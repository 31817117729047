import React, { useMemo } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link } from "gatsby"
import Layout from '../components/layout';

function Contact() {
  return (
    <Layout>
      <Typography component="h2" variant='h2'>
        Have question, feedback, or just want to say hello? Contact US
      </Typography>
      <Typography>
      Best way to contact as is by email because we're small family owned company so we don't have resources to stand next to the phone and wait for calls. We're busy on developing products for YOU!  
    </Typography>
    <Typography>
      Email us at support(at)forestjoker.com or if you still feeling lucky you can try to reach out us by call: +1 (307) 285-9200
    </Typography>
      <Typography
        sx={{
          textAlign: "center"
        }}>
        <Link to="/">Click to Go back Home</Link>
      </Typography>

    </Layout>
  )
}

export default Contact;